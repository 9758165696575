<template>
  <LayoutDossierPromoteurs>
    <template #progress_bar>
      <progress-bar
        section="Bilan financier"
        :progress_section="progress_section.bilan_financier"
      />
    </template>
    <template #current_page>Bilan financier</template>
    <template #body>
      <div
        class="text-2xl text-center font-semibold h-50-screen grid place-items-center"
        v-if="!informationsStore.type_bien"
      >
        Vous devez choisir le type du bien avant d'ajouter le bilan financier
      </div>
      <div
        v-if="informationsStore.type_bien"
        class="flex justify-end items-center border-b-2 pb-2 gap-4"
      >
        <div class="">
          <Checkbox
            id="publié"
            :val="bilan.is_published"
            v-model="bilan.is_published"
          />
          <span
            @click="bilan.is_published = !bilan.is_published"
            class="ml-8 font-normal text-base text-gray-650 font-arial cursor-pointer"
          >
            Publier
          </span>
        </div>
        <button
          @click="toggleChangeTemplateModal"
          class="font-semibold text-white bg-promy-green-300 hover:bg-promy-green-400 py-2 px-4 rounded-lg text-lg"
        >
          Changer template
        </button>
        <button
          @click="toggleAddTopicModal"
          class="font-semibold text-white bg-promy-green-300 hover:bg-promy-green-400 py-2 px-4 rounded-lg text-lg"
        >
          Ajouter Champ
        </button>
      </div>
      <div
        v-if="informationsStore.type_bien"
        class="flex flex-col"
        :key="refreshToken"
      >
        <TopicSheet
          v-if="bilan.recapTopic"
          :key="0 + bilan.recapTopic.label"
          :topic="bilan.recapTopic"
          :topicIndex="0"
          :isReadOnly="false"
          :ref="'sheet' + bilan.recapTopic.label"
        />
        <draggable
          class="flex flex-col m-4 list-group"
          :key="refreshToken"
          tag="div"
          v-model="bilan.draggableTopics"
          @end="dragEnd"
          @start="drag = true"
          handle=".handle"
        >
          <transition-group
            type="transition"
            :name="!drag ? 'flip-list' : null"
          >
            <TopicSheet
              v-for="(topic, idx) in bilan.draggableTopics"
              @dataChange="topicDataChange"
              @cellLockChange="topicCellLockChange"
              :key="idx + topic.label"
              :topic="topic"
              :topicIndex="idx"
              @deleteTopic="deleteTopic"
              :isReadOnly="false"
              :ref="'sheet' + topic.label"
            />
          </transition-group>
        </draggable>
      </div>
      <template v-if="usedTemplateId">
        <ChangeTemplateModal
          ref="changeTempateModalRef"
          :type="type"
          :usedTemplateId="usedTemplateId"
          v-on:changeTemplate="changeTemplate"
        ></ChangeTemplateModal>
        <AddTopicModal
          ref="addTopicModalRef"
          @addTopic="addTopic"
        ></AddTopicModal>
      </template>
    </template>
    <template #btn-actions>
      <sauvgard-publier-buttons @saveInfos="saveInfos()" />
    </template>
  </LayoutDossierPromoteurs>
</template>
<script>
import { mapGetters } from 'vuex'
import progress from '@/mixins/dossierPromoteurs/progress'
import TopicSheet from '../../../components/BilanFinancier/TopicSheet.vue'
import ChangeTemplateModal from './ChangeTemplateModal.vue'
import draggable from 'vuedraggable'
import bilan from '@/mixins/jspreadsheet/bilan.mixin'

export default {
  mixins: [bilan, progress],
  components: {
    ChangeTemplateModal,
    draggable,
  },
  data() {
    return {
      bilan: {},
      refreshToken: 0,
      drag: false,
      is_loading: true,
    }
  },
  created() {
    this.$store.commit('dossierpromoteur/setProgressSection', {
      section: 'bilan_financier',
      value: 100,
    })

    if (this.dossier_bilan.template_id) {
      this.syncBilan(this.dossier_bilan)
    } else if (Object.keys(this.default_bilan_template).length !== 0) {
      this.syncBilan(this.default_bilan_template)
    } else {
      if (this.informationsStore.type_bien) {
        this.$store
          .dispatch(
            'dossierpromoteur/getDefaultBilanTemplateByType',
            this.informationsStore.type_bien,
          )
          .then((res) => {
            this.syncBilan(res)
            this.refreshToken++
          })
      }
    }
  },
  methods: {
    dragEnd() {
      this.drag = false
      this.refreshToken++
    },
    toggleAddTopicModal() {
      this.$refs.addTopicModalRef.toggleAddTopicModal()
    },
    addTopic(topic) {
      this.bilan.draggableTopics.push(topic)
      this.refreshToken++
    },
    toggleChangeTemplateModal() {
      this.$refs.changeTempateModalRef.toggleChangeTemplateModal()
    },
    changeTemplate(template) {
      this.bilan = template
      this.$store.commit('bilanFinancier/RESET_PERCENTAGES')
      this.refreshToken++
    },
    deleteTopic(index) {
      this.bilan.draggableTopics.splice(index, 1)
      this.refreshToken++
    },
    topicDataChange({ index, data }) {
      this.bilan.draggableTopics[index].template.data = data
    },
    topicCellLockChange() {
      this.bilan.draggableTopics.forEach((t) => {
        const sheetRef = this.$refs['sheet' + t.label][0]
        sheetRef.isLocked = sheetRef.getIsLocked(sheetRef.jExcelObjects[0])
      })
    },
    saveInfos() {
      try {
        this.bilan.topics = [
          this.bilan.recapTopic,
          ...this.bilan.draggableTopics,
        ]
        const topics = this.bilan.topics.map((topic, i) => {
          const sheetRef = Array.isArray(this.$refs['sheet' + topic.label])
            ? this.$refs['sheet' + topic.label][0]
            : this.$refs['sheet' + topic.label]
          const template = sheetRef.topicToSave().template

          return {
            ...(topic.id && { id: topic.id }),
            label: topic.label,
            locked: sheetRef.isLocked,
            data: template,
            order: i,
          }
        })

        this.$store.commit(
          'dossierpromoteur/SET_BILAN_FINANCIER_TEMPLATE_ID',
          this.bilan.hasOwnProperty('template_id')
            ? this.bilan.template_id
            : this.bilan.id,
        )
        this.$store.commit('dossierpromoteur/SET_BILAN_FINANCIER', topics)
        this.$store.commit(
          'dossierpromoteur/SET_BILAN_FINANCIER_IS_PUBLISHED',
          this.bilan.is_published,
        )
      } catch (e) {
        console.log(e)
        this.$toaster.warning("Problem de l'enregistrement!")
      }
    },
    syncBilan(templateOrBilan) {
      this.bilan.is_published = false
      this.bilan.topics = templateOrBilan.topics
      this.bilan.draggableTopics = templateOrBilan.topics.filter(
        (t, i) => i !== 0,
      )
      this.bilan.recapTopic = templateOrBilan.topics[0]
      this.bilan.default = templateOrBilan.default
      this.bilan.name = templateOrBilan.name
      if (templateOrBilan.hasOwnProperty('template_id')) {
        this.bilan.is_published = templateOrBilan.is_published
        this.bilan.template_id = templateOrBilan.template_id
      } else this.bilan.id = templateOrBilan.id
    },
  },
  computed: {
    ...mapGetters({
      informationsStore: 'dossierpromoteur/getinfos',
      progress_section: 'dossierpromoteur/getProgressSection',
      default_bilan_template: 'dossierpromoteur/getDefaultBilanTemplate',
      dossier_bilan: 'dossierpromoteur/getBilanFinancier',
    }),
    usedTemplateId() {
      if (this.bilan && this.bilan.template_id) {
        return this.bilan.template_id
      } else if (this.bilan.id) {
        return this.bilan.id
      }

      return null
    },
    type() {
      return this.default_bilan_template.type.id
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
  },
}
</script>
