<template>
  <Modal v-if="changeTemplateModal" :isLarge="true">
    <slot slot="header">
      <h1 class="text-promy-purple-400 font-black text-xl font-main">
        Changer la template :
      </h1>
    </slot>
    <slot slot="body">
      <div class="flex items-center px-6 mb-4">
        <p class="w-1/4">Nom</p>
        <p class="w-1/4">Type</p>
        <p class="w-1/4">Version</p>
        <p class="w-1/4">Par défault ?</p>
        <p class="w-1/4">Date de création</p>
      </div>
      <div
        class="flex items-center bg-white p-6 rounded-lg mb-4 cursor-pointer hover:shadow-lg relative"
        v-for="(template, index) in templates"
        :key="index"
        @click="selectTemplate(template)"
      >
        <div
          class="absolute border border-promy-green-400 w-full h-full top-0 left-0 rounded-lg flex justify-end items-end"
          v-if="template.id == usedTemplateId"
        >
          <div
            class="bg-promy-green-400 rounded-tl-lg rounded-br-lg text-white text-sm px-2"
          >
            Sélectionnée
          </div>
        </div>
        <p class="w-1/4 pl-2">{{ template.name }}</p>
        <p class="w-1/4 pl-2">{{ template.type.name }}</p>
        <p class="w-1/4">{{ template.version }}</p>
        <p class="w-1/4">
          <span
            v-if="template.default"
            class="text-promy-green-400 p-1 bg-white border-2 border-promy-green-400 rounded-lg font-bold"
            >Par défault</span
          >
          <span v-else></span>
        </p>
        <p class="w-1/4">{{ template.created_at | filterDate }}</p>
      </div>
    </slot>
    <slot slot="footer">
      <button
        class="font-extrabold text-white uppercase border-2 border-solid rounded-full font-main hover:bg-promy-red hover:border-promy-red border-promy-red-100 bg-promy-red-100 px-6 py-2 sm:w-full md:mt-8 mr-4"
        @click="toggleChangeTemplateModal"
      >
        Annuler
      </button>
    </slot>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    type: {
      type: Number,
      default: null,
    },
    usedTemplateId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      changeTemplateModal: false,
      page: 1,
    }
  },
  computed: {
    ...mapGetters({
      templates: 'bilanFinancier/getTemplates',
    }),
  },
  created() {
    const payload = {
      page: this.page,
      type: this.type,
    }

    this.$store.dispatch('bilanFinancier/getTemplatesForType', payload)
  },
  methods: {
    toggleChangeTemplateModal() {
      this.changeTemplateModal = !this.changeTemplateModal
    },
    selectTemplate(template) {
      if (this.usedTemplateId != template.id) {
        this.$emit('changeTemplate', template)
        this.changeTemplateModal = false
      }
    },
  },
}
</script>

<style></style>
